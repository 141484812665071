import React, { useEffect } from 'react';
import { useLocation } from 'react-router-dom';
import BaseUrlConfig from '../net/BaseUrlConfig';
function RedirectComponent() {
  const location = useLocation();

  useEffect(() => {
    const currentPath = location.pathname;
    
    // 如果是登录页面，不执行重定向逻辑
    // !location.pathname || location.pathname === '/'
    // 如果是登录页面，不执行重定向逻辑
    console.log('currentPath:'+currentPath)
    if (location.pathname === '/' || currentPath === '/login' || currentPath === '/home' 
    || currentPath.includes('pinpoint-college') || currentPath.includes('/questionnaire-answer')) {
      return;
    }
    const activeProfile = BaseUrlConfig.profile;
    const cookieLocation = document.cookie
      .split('; ')
      .find(row => row.startsWith('officeLocation='))
      ?.split('=')[1];
    
    const cookieUserName = document.cookie
      .split('; ')
      .find(row => row.startsWith('cmsUserName='))
      ?.split('=')[1];
    if (document.cookie
      .split('; ')
      .find(row => row.startsWith('cmsUserName='))
      ?.split('=')[1] === 'lu.xin') {
      return;
    }
    
    if (activeProfile === 'prodsh' && cookieLocation !== undefined) {
      if (cookieLocation !== 'Shanghai' && cookieLocation !== 'SanYa') {
        window.location.href = `https://cms.pinpointfund.com${location.pathname}`;
      }
    } else if (activeProfile === 'prod' && cookieLocation !== undefined) {
      if (cookieLocation !== 'HongKong' && cookieLocation !== 'Singapore' && cookieLocation !==  'India' && cookieLocation !==  'Japan'&& cookieLocation !==  'Taipei') {
        window.location.href = `https://cms.pinpointfund.cn${location.pathname}`;
      }
    }


  }, [location]);

  return null;
}

export default RedirectComponent;
